import {
  type MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import crypto from 'crypto';

import { signUpYahoo, type YahooAuthAPIResult } from '@src/apiCalls';
import { autoFill as squatchAutoFill } from '@src/lib/saasquatch';
import { CookieName, getCookie } from '@src/utils/cookies';
import { RedirectOverlay } from '@src/components/redirect-overlay';
import { primarySignupFormVar } from '@src/apollo/local';
import getConfig from 'next/config';
import { SSOButton } from '@src/components/sso-buttons/sso-button';

const {
  NEXT_PUBLIC_YAHOO_AUTH_API_URL,
  NEXT_PUBLIC_YAHOO_AUTH_CLIENT_ID,
  NEXT_PUBLIC_YAHOO_AUTH_REDIRECT_URI,
  NEXT_PUBLIC_YAHOO_SSO_ON,
} = getConfig().publicRuntimeConfig;

const YAHOO_SSO_ON = NEXT_PUBLIC_YAHOO_SSO_ON === 'true';
const YAHOO_AUTH_API_URL = NEXT_PUBLIC_YAHOO_AUTH_API_URL;
const YAHOO_AUTH_CLIENT_ID = NEXT_PUBLIC_YAHOO_AUTH_CLIENT_ID;
const YAHOO_AUTH_REDIRECT_URI = NEXT_PUBLIC_YAHOO_AUTH_REDIRECT_URI;

const buildYahooAuthUri = (): string => {
  const nonce = crypto
    .createHash('sha1')
    .update(Date.now().toString())
    .update(Math.floor(Math.random() * 1000).toString())
    .digest('hex');

  return `${YAHOO_AUTH_API_URL}?client_id=${YAHOO_AUTH_CLIENT_ID}&response_type=code&redirect_uri=${YAHOO_AUTH_REDIRECT_URI}&scope=openid&nonce=${nonce}`;
};

type Props = {
  onClick?: MouseEventHandler;
  onSuccess: (result: YahooAuthAPIResult) => void;
  signup?: boolean;
  nurseType?: string;
  onFail?: (error: string) => void;
  signupSource?: string;
  referrerCode?: string;
  signupSourceQualifier?: string;
  viewer?: {
    firstName: string;
    lastName: string;
  };
};

const YahooLoginButton = ({
  onClick,
  onSuccess,
  onFail,
  viewer,
  ...props
}: Props) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [authCode, setAuthCode] = useState<string | null | undefined>();
  const { nurseProfile } = primarySignupFormVar();
  const checkCodeRef = useRef<NodeJS.Timeout | undefined>();

  const checkCode = () => {
    const code = localStorage.getItem('code');

    if (code) {
      setAuthCode(code);
      localStorage.removeItem('code');
      return;
    }

    checkCodeRef.current = setTimeout(() => checkCode(), 1000);
  };

  const openPopup = async () => {
    localStorage.removeItem('code');
    window.open(buildYahooAuthUri(), 'popup', 'popup=true');
    checkCode();
  };

  const handleCallback = useCallback(async () => {
    if (authCode) {
      try {
        const referralCode = await squatchAutoFill();
        const referringDomain = getCookie(CookieName.ReferringDomain);
        const results = await signUpYahoo({
          code: authCode,
          signup: props.signup,
          nurse_type: props.nurseType,
          signup_source: props.signupSource,
          signup_source_qualifier: props.signupSourceQualifier,
          initial_referring_domain: referringDomain,
          referrer_code: props.referrerCode ? [props.referrerCode] : [],
          referral_code: referralCode ?? undefined,
          role_id: nurseProfile.roleId,
          first_name: viewer?.firstName || '',
          last_name: viewer?.lastName || '',
        });

        setShowLoader(false);
        localStorage.setItem('LAST_USED_SSO', 'yahoo');
        onSuccess(results);
      } catch (e) {
        setShowLoader(false);
        onFail?.(
          'Could not sign up using Yahoo!. Please try signing up with your email instead.'
        );
      }
    }
  }, [nurseProfile, props, onFail, onSuccess, authCode, viewer]);

  useEffect(() => {
    if (authCode) {
      handleCallback();
    }
  }, [authCode, handleCallback]);

  useEffect(() => {
    // Clear checkCode timeout when unmounting
    return () => {
      if (checkCodeRef.current) {
        clearTimeout(checkCodeRef.current);
      }
    };
  }, []);

  if (showLoader) {
    return <RedirectOverlay message="Loading..." />;
  }

  return <SSOButton provider="yahoo" onClick={() => openPopup()} />;
};

export const YahooButton = (props: Props) => {
  if (!YAHOO_SSO_ON) {
    return null;
  }

  return <YahooLoginButton {...props} />;
};
