import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import getConfig from 'next/config';

import { type GoogleAuthAPIResult, signUpGoogle } from '@src/apiCalls';
import { primarySignupFormVar } from '@src/apollo/local';
import { notifyError } from '@src/lib/error-reporter';
import { SSOButton } from '@src/components/sso-buttons/sso-button';

const { GOOGLE_CLIENT_ID } = getConfig().publicRuntimeConfig;

type Props = {
  success: (result: GoogleAuthAPIResult) => void;
  onFail?: (error: string) => void;
  signup?: boolean;
  nurseType?: string;
  googleAccountNotFound?: () => void;
  viewer?: {
    firstName: string;
    lastName: string;
  };
};

const errorMessage =
  'Could not sign up using Google. Please try signing up with your email instead.';

const GButton = ({
  success,
  onFail,
  googleAccountNotFound,
  viewer,
  ...props
}: Props) => {
  const { nurseProfile } = primarySignupFormVar();

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      const params = {
        google_access_token: tokenResponse.access_token,
        signup: props.signup,
        nurse_type: props.nurseType,
        role_id: nurseProfile.roleId,
        first_name: viewer?.firstName || '',
        last_name: viewer?.lastName || '',
      };

      signUpGoogle(params)
        .then(result => {
          localStorage.setItem('LAST_USED_SSO', 'google');
          success(result);
        })
        .catch(e => {
          if (e instanceof Error && e.message === 'google account not found') {
            googleAccountNotFound?.();
          }

          onFail?.(errorMessage);
        });
    },
    onError: () => onFail?.(errorMessage),
    onNonOAuthError: data => {
      onFail?.(errorMessage);

      notifyError('Google non OAuth Error', {
        extraSections: { metadata: { data } },
      });
    },
  });

  return <SSOButton provider="google" onClick={() => googleLogin()} />;
};

export const GoogleButton = (props: Props) => {
  if (!GOOGLE_CLIENT_ID) {
    return null;
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GButton {...props} />
    </GoogleOAuthProvider>
  );
};
